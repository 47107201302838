import './App.css';
import {createRef, useState} from "react";
import {Track} from "../functions/api/playlist/[[id]]";

const BACKEND_URL = 'https://hello-world-floral-pond-2306.holysheet.workers.dev';

function getPlaylistIdFromUrl(url: string): string | undefined {
  try {
    const urlObj = new URL(url);
    if (urlObj.hostname === "open.spotify.com" && urlObj.pathname.startsWith('/playlist/')) {
      return urlObj.pathname.split('/')[2];
    }
  } catch (e) {}
}

function getTracks(id: string): Promise<Track[]> {
  return fetch(`${BACKEND_URL}?id=${id}`).then(async res => {
    if (res.status != 200) {
      return []
    }
    
    return await res.json() as Track[];
  })
}

function App() {
  const urlRef = createRef<HTMLInputElement>()
  const [csvValue, setCsvValue] = useState<string>('')
  
  const generateCSV = async (): Promise<void> => {
    if (urlRef.current == undefined) {
      return
    }

    let playlistId = getPlaylistIdFromUrl(urlRef.current.value)

    if (playlistId == undefined) {
      setCsvValue('Bad URL')
    }

    let tracks = await getTracks(playlistId as string)
    
    let trackCsv = ''
    for (let track of tracks) {
      trackCsv += `${track.artist},${track.title.replace(',', '\\,')}\n`
    }

    setCsvValue(trackCsv.trim())
  }
  
  return (
    <div className="App">
      <div className="content">
        <input ref={urlRef} placeholder="Spotify URL here!"></input>
        <button onClick={() => generateCSV()}>yeah</button>
        <textarea value={csvValue}></textarea>
      </div>
    </div>
  );
}

export default App;
